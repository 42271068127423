export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/',
        fontIcon: 'fas fa-home',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Admins',
        to: '/admins',
        fontIcon: 'fas fa-user',
        permissions: [
          "admins_page_tab", "admins_crud", "roles_page_tab", "roles_crud", "permissions_page_tab"
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Instructors',
        to: '/instructors',
        fontIcon: 'fas fa-user',
        permissions: [
          'instructors_page_tab',
          'instructors_create_invite',
          'instructors_crud'
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'End Users',
        to: '/users',
        fontIcon: 'fas fa-user',
        permissions: [
          'users_page_tab',
          'users_settings',
          'users_crud'
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Classes',
        to: '/classes',
        fontIcon: 'fas fa-user',
        permissions: [
          'classes_list_page_tab',
          'classes_categories',
          'classes_calendar',
          'help_video_page_tab'
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Enterprise Users',
        to: '/enterprise-users',
        fontIcon: 'far fa-address-card',
      },

    ]
  }
]